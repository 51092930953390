<template>
  <v-container>
    <v-card class="mb-4 pa-4" outlined>
      <v-card-title>Select Project Code</v-card-title>
      <v-card-subtitle class="text--secondary">
        Choose a project code to load data for
      </v-card-subtitle>
      <v-divider class="my-4"></v-divider>
      <v-select
        v-model="selectedProjectCode"
        :items="formattedProjectCodes"
        item-text="label"
        item-value="code"
        label="Select Project Code"
        outlined
        dense
        class="mb-4"
      ></v-select>
      <v-btn
        :disabled="!selectedProjectCode"
        @click="getProjectData"
        color="primary"
        large
        block
        elevation="2"
      >
        Get Project Data
      </v-btn>
      <v-data-table
        v-if="filteredProjectData.length"
        :headers="projectHeaders"
        :items="filteredProjectData"
        class="elevation-1 mb-4"
        :items-per-page="10"
        dense
      >
        <template v-slot:item.view="{ item }">
          <v-btn text color="primary" @click="$router.push({ path: `/mobile/v2/project/data/${item._id}` })">
            View Submission
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import RestResource from "../../../services/dataServiceMobileV2.js";
const service = new RestResource();

export default {
  data() {
    return {
      projectCode: [],
      projectData: [],
      projectSearch: "",
      selectedProjectCode: null,
      projectHeaders: [
        { text: "Submission Id", value: "_id" },
        { text: "User Id", value: "userId" },
        { text: "Email", value: "email" },
        { text: "View Submission", value: "view" },
      ],
    };
  },

  computed: {
    formattedProjectCodes() {
      return this.projectCode.map((project) => ({
        label: `${project.code} - ${project.projectName}`,
        code: project.code,
      }));
    },
    filteredProjectData() {
      const search = this.projectSearch.toLowerCase();
      return this.projectData.filter((data) =>
        Object.values(data).some((value) =>
          String(value).toLowerCase().includes(search)
        )
      );
    },
  },

  mounted() {
    this.getProjectCodes();
  },

  methods: {
    async getProjectCodes() {
      this.$setLoader();
      let res = await service.getProjectCodes();
      this.projectCode = res.data.data;
      this.$disableLoader();
    },

    async getProjectData() {
      if (!this.selectedProjectCode) return;
      this.$setLoader();
      let res = await service.getProjectData({
        projectCode: this.selectedProjectCode,
      });
      this.projectData = res.data.data;
      this.$disableLoader();
    },
  },
};
</script>
